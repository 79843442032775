
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">

      <InPageLoadingIndicator :message="loadingMessage"/>

    </div>
  </div>
</template>

<script>

import InPageLoadingIndicator from "../components/ui/InPageLoadingIndicator.vue";

export default {
  name: 'ReloadAndRedirectPage',
  components: {
    InPageLoadingIndicator
  },
  data: function() {
    return {
      loadingMessage: "Lade Daten ..."
    }
  },
  methods: {
    doRedirect() {
      let path = this.$route.query.path
      if (!path) {
        this.loadingMessage = "Kein Pfad angegeben."
        return
      }
      if (this.$store.getters["ui/reload"]) {
        this.$store.commit("ui/reload", false)
        window.$localDBSync.cancel()
        window.$localContentDBSync.cancel()

        window.$localDB.changes().on('complete', () => {
          window.location.reload()
        });
        console.log("Reloading page.")
      } else {
        this.$router.push(
          { path: path, query: { delay: this.$route.query.delay } }
        )
      }
      if (this.$route.query.delay == "true") {
        this.loadingMessage = "Aktualisiere Daten ..."
      }
    },
  },
  created() {
    this.doRedirect()
  },
}
</script>

