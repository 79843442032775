
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <WidgetRow gridTemplateColumns="1fr" flexGrow="1">
        <template #title>
          <h4 class="widget-title">{{ userName }} <span v-if="teamEnabled">(Ich)</span></h4>
          <RolesBadges  v-if="teamEnabled" class="role-badge"/>
        </template>
        <ProjectItem :headerOnly="true" />

        <WidgetContainer class="full-width">
          <div v-if="projects && projects.length > 0">
            <ProjectItem
              class="project-item"
              v-for="(project, index) in projects"
              v-bind:key="'project_' + index"
              :project="project"
              source="my-projects"
              :team="team"
              :holderId="userId"
              ownerName="Ich"
              @project:assign="onAssignProject"
              @project:delete="onDeleteProject"
              :checkoutInfo="projectsCheckoutMap ? projectsCheckoutMap[project._id] : null"
            />
          </div>
          <div v-if="projects.length == 0" class="no-projects">Keine Projekte.</div>
          <div
            v-if="lentProjectsInfo(userId, projects).length"
            :class="'table-footer no-projects' + (availableProjects(userId, projects).length > 0 ? ' with-margin-top' : '')"
          >
              {{ lentProjectsInfo(userId, projects) }}
          </div>
        </WidgetContainer>
      </WidgetRow>
      <MemberProjects
        ref="memberProjects"
        v-if="canReadTeamMemberProjects"
        :checkouts="projectsCheckoutMap"
        @project:assign="onAssignProject"
        :team="team"
      />
    </div>
    <AlertScreen :isPresented="projectToBeRemoved != null">
      <template #icon>
      </template>
      <template #message>Wollen sie das Projekt wirklich löschen?</template>
      <template #content>Dies kann nicht rückgängig gemacht werden.</template>
      <template #actions>
        <button class="button primary full-width danger" @click.prevent="doDeleteProject">Projekt löschen</button>
        <button class="button plain full-width" @click.prevent="onCancelDeleteProject">Abbrechen</button>
      </template>
    </AlertScreen>
  </div>
</template>

<script>

import ProjectItem from '../components/projects/ProjectItem.vue'
import WidgetContainer from '../components/widgets/WidgetContainer.vue'
import WidgetRow from "../components/widgets/WidgetRow.vue"
import MemberProjects from "../components/team/MemberProjects.vue"
import TeamMixin from "../components/mixins/TeamMixin.vue"
import RolesBadges from "../components/user/RolesBadges.vue"
import axios from "axios"
import { AlertScreen } from "@profineberater/configurator-clientlib"

const baseURL = process.env.VUE_APP_SERVER_CONFIGURATOR_URL

export default {
  name: 'ProjectsPage',
  mixins: [TeamMixin],
  components: {
    ProjectItem,
    WidgetContainer,
    WidgetRow,
    MemberProjects,
    RolesBadges,
    AlertScreen
  },
  data: function() {
    return {
      projectToBeRemoved: null
    }
  },
  computed: {
    projects() {
      return this.$store.state.project.projects
    },
    userId() {
      return this.$store.getters["user/userId"]
    },
    userName() {
      return this.$store.getters["user/userName"]
    },
    userFirstName() {
      return this.$store.getters["user/userFirstName"]
    },
    userLastName() {
      return this.$store.getters["user/userLastName"]
    },
    teamEnabled() {
      return this.$store.getters["team/teamEnabled"]
    },
    team() {
      return this.$store.getters["team/team"]
    },
  },
  methods: {
    onEditProject() {
      this.refresh()
    },
    onDeleteProject(project) {
      this.projectToBeRemoved = project
    },
    doDeleteProject() {
      axios({
        url: `${baseURL}/api/v1/team/project/${this.projectToBeRemoved._id}`,
        method: 'delete',
        headers: { 'Authorization': `Bearer ${window.$keycloak.token}` },
      }).then((result) => {
        console.log("Deleted project", result.data)
        this.projectToBeRemoved = null
        this.refresh()
      }).catch(error => {
        console.error("Failed to delete project", error)
        this.projectToBeRemoved = null
        this.$store.commit("ui/error", "Beim Löschen des Projekts ist ein Fehler aufgetreten.")
      })
    },
    onCancelDeleteProject() {
      this.projectToBeRemoved = null
    },
    refresh() {
      this.$store.dispatch("project/getAll")
      this.$store.dispatch("team/fetchTeam").then( () => {
        this.$refs.memberProjects?.refresh()
      }).catch( error => {
        console.error("Failed to load team", error)
        this.$store.commit("ui/error", "Beim Laden der Teamdaten ist ein Fehler aufgetreten.")
      })
    },
    onAssignProject(fromUserId, toUserId, project) {
      axios({
        url: `${baseURL}/api/v1/team/project/${project._id}/assign`,
        method: 'post',
        headers: { 'Authorization': `Bearer ${window.$keycloak.token}` },
        data: { fromUserId, toUserId }
      }).then((result) => {
        console.log("Assigned project", result.data)
        //this.$emit("project:assigned", result.data)
        this.refresh()
      }).catch(error => {
        console.error("Failed to import project", error)
        this.refresh()
        this.$store.commit("ui/error", "Beim Zuweisen des Projekts ist ein Fehler aufgetreten.")
      })
    },
  },
  created() {
    this.refresh()
  }
}
</script>
<style scoped>
  .role-badge {
    margin-bottom: 20px;
  }

  :deep(.project-item .header) {
    padding-right: 70px;
  }
  :deep(.no-projects) {
    color: var(--text-color);
    font-size: 14px;
    text-align: left;
    margin-left: 20px;
  }
  :deep(.table-footer) {
    font-size: 14px;
    color: var(--text-color);
    margin-left: 20px;
  }
  :deep(.table-footer.with-margin-top) {
    margin-top: 20px;
  }
</style>