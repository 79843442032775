const initialState = {
  app: 'backoffice',
  isShowingModal: false,
  isLoading: false,
  isMasterViewBlocked: false,
  isScreenBlocked: false,
  isUIUnblockByUserDisabled: false,
  isSiteMenuShown: false,
  contextMenuState: {
    active: false,
    top: 0,
    left: 0,
    data: null
  },
  vendor: {
    phone: "+49 (0)63 31 / 56 - 1139",
    email: "info@profine-esolutions.com"
  },
  error: null,
  isBlockingSyncInProgress: false,
  isUserDBSyncInProgress: false,
  isAssortmentEnabled: true,
  debug: false,
  companyValidationModel: {
    companyName: {},
    streetAndNumber: {},
    zip: {},
    city: {},
    phone: {},
    mobile: {},
    email: {},
    website: {},
  },
  profileValidationModel: {
    firstName: {},
    lastName: {},
    companyName: {},
    streetAndNumber: {},
    zip: {},
    city: {},
    country: {},
    phone: {},
    mobile: {},
    email: {},
    website: {},
  },
  teamValidationModel: {
    teamName: {},
  },
  reload: false, // used to force reload of page
  uploadedTeamLogo: null,
  showUploadedTeamLogo: false,
  deleteTeamLogoPending: false,
}

var deferrBlockingSyncTimer = null
const deferBlockingSyncInterval = 1000

export const ui = {
  namespaced: true,
  state: () => { return initialState },
  mutations: {
    isLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    isMasterViewBlocked(state, isMasterViewBlocked) {
      state.isMasterViewBlocked = isMasterViewBlocked
    },
    isScreenBlocked(state, isScreenBlocked) {
      state.isScreenBlocked = isScreenBlocked
    },
    isUIUnblockByUserDisabled(state, isUIUnblockByUserDisabled) {
      state.isUIUnblockByUserDisabled = isUIUnblockByUserDisabled
    },
    contextMenuState(state, menuState) {
      state.contextMenuState = menuState
    },
    contextMenuActive(state, active) {
      let menuState = state.contextMenuState
      menuState.active = active
      state.contextMenuState = menuState
    },
    isSiteMenuShown(state, isSiteMenuShown) {
      state.isSiteMenuShown = isSiteMenuShown
    },
    debug(state, debug) {
      state.debug = debug
    },
    error(state, error) {
      if (error && error.response && error.response.data && error.response.data.error) {
        state.error = error.response.data.error
      } else {
        state.error = error
      }
    },
    isBlockingSyncInProgress(state, value) {
      state.isBlockingSyncInProgress = value
      state.isLoading = value
      state.isMasterViewBlocked = value
      if (deferrBlockingSyncTimer) {
        state.isBlockingSyncInProgress = false
        clearTimeout(deferrBlockingSyncTimer)
      }
    },
    setBlockingSyncInProgressDeferred(state) {
      deferrBlockingSyncTimer = window.setTimeout(() => {
        state.isBlockingSyncInProgress = true
        state.isLoading = true
        state.isMasterViewBlocked = true
      }, deferBlockingSyncInterval)
    },
    isUserDBSyncInProgress(state, value) {
      state.isUserDBSyncInProgress = value
    },
    reload(state, reload) {
      state.reload = reload
    },
    companyValidationModel(state, companyValidationModel) {
      state.companyValidationModel = companyValidationModel
    },
    profileValidationModel(state, profileValidationModel) {
      state.profileValidationModel = profileValidationModel
    },
    teamValidationModel(state, teamValidationModel) {
      state.teamValidationModel = teamValidationModel
    },
    uploadedTeamLogo(state, logo) {
      state.uploadedTeamLogo = logo
    },
    showUploadedTeamLogo(state, show) {
      state.showUploadedTeamLogo = show
    },
    deleteTeamLogoPending(state, pending) {
      state.deleteTeamLogoPending = pending
    }
  },
  getters: {
    debug(state) {
      return state.debug
    },
    companyValidationModel(state) {
      return state.companyValidationModel
    },
    profileValidationModel(state) {
      return state.profileValidationModel
    },
    teamValidationModel(state) {
      return state.teamValidationModel
    },
    reload(state) {
      return state.reload
    },
    isUserDBSyncInProgress(state) {
      return state.isUserDBSyncInProgress
    },
    isBlockingSyncInProgress( state ) {
      return state.isBlockingSyncInProgress
    },
    app(state) {
      return state.app
    },
    uploadedTeamLogo(state) {
      return state.uploadedTeamLogo
    },
    showUploadedTeamLogo(state) {
      return state.showUploadedTeamLogo
    },
    deleteTeamLogoPending(state) {
      return state.deleteTeamLogoPending
    },
  },
  actions: {
    toggleContextMenu({ commit, state }, payload) {
      let el = payload.event.target
      if (el.tagName != 'div') {
        el = el.closest('div')
      }
      var rect = el.getBoundingClientRect();
      let menuEntryHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--context-menu-entry-height'))
      let menuHeight = (payload.menuItems.length + 1) * menuEntryHeight
      let menuTop = (rect.top + rect.height + 10)
      let menuState = {
        top: menuTop + 'px',
        left: rect.left + 'px',
        active: !state.contextMenuState.active,
        data: payload.data,
        menuItems: payload.menuItems
      }
      if (menuTop + menuHeight > window.innerHeight) {
        menuTop -= menuHeight
        menuState.top = menuTop + 'px'
      }
      commit('contextMenuState', menuState)
    },
    resetCompanyValidationModel({ commit }) {
      commit('companyValidationModel', JSON.parse(JSON.stringify(initialState.companyValidationModel)))
    }

  },
};
